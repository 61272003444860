<!-- 房情中心 -->
<template>
  <section class="cont roomFeelingCenter">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>集团管理</el-breadcrumb-item>
        <el-breadcrumb-item>房情中心</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-row class="content-box">
      <el-alert type="warning" title="可售=可售房间数； 占用=已售房间数；不可售=关房房间数；超售=该房型的订单数-(总房间数-不可售)" :closable="false" show-icon></el-alert>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="left-btn">
            <label>选择酒店：</label>
            <el-select v-model="hotelId" placeholder="请选择">
              <el-option v-for="item in hotelList" :key="item.id" :label="item.hotelName" :value="item.id"></el-option>
            </el-select>
            <label class="m-left-25">选择日期：</label>
            <span class="el-date-editor--prevquantum" :class="{prohibit:beginTime < todayNextWeek}" @click="changeDate('-')">
              <i class="el-icon-arrow-left"></i>
            </span>
            <el-date-picker value-format="yyyy-MM-dd" :clearable="false" class="width-140" @change="getRoomSituation" :editable="false" v-model="beginTime" type="date" :picker-options="pickerOptions"/>
            <span class="el-date-editor--nextquantum" @click="changeDate('+')">
              <i class="el-icon-arrow-right"></i>
            </span>
          </el-row>
        </div>
        <!-- 一周房情信息 -->
        <el-table :data="tableData.list" border fit :stripe="true" :header-cell-style="{ background: '#f0f3fc',textAlign: 'center' }" v-loading="loading">
          <el-table-column fixed prop="roomTypeName" label="房型" width="235" align="center"></el-table-column>
          <el-table-column fixed prop="roomTotalNum" label="数量" width="95" align="center"></el-table-column>
          <el-table-column :label="`${item.dateStr}${item.weekName}`" v-for="(item,index) in tableData.header" :key="index">
            <el-table-column :prop="i.key" :label="i.title" width="80" v-for="i in item.children" :key="i.key" align="center"></el-table-column>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { pms } from '@/api/interface/pms';
import { dateFactory } from '@/common/js/common';
export default {
  data(){
    return{
      todayNextWeek:"",        // 下周的今天
      hotelList:[],            // 酒店列表 
      hotelId:"",              // 酒店ID
      beginTime:"",            // 查询时间

      tableData:[],            // 表格数据
      loading:false,           // 表格加载动画

      // 日历快捷选项配置
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
    }
  },
  watch:{
    hotelId(){
      this.getRoomSituation();
    }
  },
  mounted(){
    // 7天后的日期 "2022-03-11"
    this.todayNextWeek = dateFactory.getDistanceToday(7,false);
    this.beginTime = dateFactory.getDistanceToday(0,false);
    this.getholtel()
  },
  methods:{
    // 获取酒店列表
    getholtel(){
      const url = urlObj.getHotelslist;
      const param = { };
      this.$axios.post(url, param, 'json').then(res => {
        if (res.success){
          this.hotelList = res.records;
          this.hotelId = this.hotelList[0].id;
        }
      })
    },
    // 点击按钮触发时间改变
    changeDate(method){
      if ( method==='-' && this.beginTime < this.todayNextWeek ) {
        return false;
      }else{
        this.beginTime = dateFactory.getDistanceAppoint(this.beginTime, method, 7, false);
        this.getRoomSituation();
      }
    },
    // 获取房情信息
    getRoomSituation(){
      const url = pms.getRoomSituation;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        beginDate:this.beginTime,                        // 开始日期
        // days:6,                                         // 天数
        roomTypeSource: "PMS"
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.tableData = res.records;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.roomFeelingCenter{
  .el-alert--warning.is-light{
    padding: 10px;
    border: 1px solid #fa0;
    margin-bottom: 20px;
  }
  .el-date-editor--prevquantum{
    display: inline-block;
    width: 32px;
    height: 40px;
    line-height: 35px;
    border: 1px solid #e0e0e0;
    border-right: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
  }
  .el-date-editor--nextquantum{
    display: inline-block;
    width: 32px;
    height: 40px;
    line-height: 35px;
    border: 1px solid #e0e0e0;
    border-left: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
  }
  /deep/ .el-input--prefix .el-input__inner{
    border-radius: 0;
  }
  .prohibit{
    cursor: not-allowed;
  }
}
</style>